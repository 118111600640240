.back {
  margin: 20px 0;
  a:hover {
    text-decoration: underline; }
  @include on($desktop) {
    margin-top: $offset; } }

.category {
  display: block;
  font-size: 15px; }

.date {
  display: block;
  font-size: 15px; }

.clients {
  li {
    display: inline-block;
    height: 40px;
    margin: 7px 10px 0 0;
    vertical-align: top;
    img {
      vertical-align: top; }
    &.microsoft {
      padding: 10px 0; }
    &.sass {
      img {
        width: 53px; } }
    &.sony {
      padding: 12px 0; }
    &.wordpress {
      padding: 2px 0; }
    &.jquery {
      padding: 10px 0 6px; }
    &.bootstrap {
      padding: 4px; } } }

.content {
  color: #444444;
  line-height: 1.5;
  a {
    border-bottom: 1px dotted currentColor;
    &:hover {
      color: $black;
      border-bottom: 1px solid currentColor; }
    &:visited {
      color: $visited; } }
  h2,
  h3 {
    color: $title;
    font-size: $size-h2;
    font-weight: 400;
    line-height: 1.125;
    margin-top: 60px;
    position: relative;
    a {
      border-bottom: none;
      font-size: $size-h2-link;
      margin-right: 10px;
      @include on($desktop) {
        position: absolute;
        right: 100%; }
      &:hover {
        &.anchor {
          border-bottom: none; } } } }
  h3 {
    font-size: $size-h3;
    a {
      font-size: $size-h3; } }
  aside {
    color: $light;
    font-size: $size-small;
    margin: 20px 0;
    padding: 10px;
    text-align: center; }
  h2,
  h3 {
    a {
      &:visited {
        color: $link; } } }
  p {
    margin: 20px 0;
    line-height: 1.5;
    &:last-child {
      margin-bottom: 0; }
    &.note {
      background: $ghostwhite;
      color: $title;
      font-size: $size-default;
      padding: 15px 20px;
      border-radius: 2px;
      code {
        background: none;
        color: $pre-color; } } }
  ol {
    list-style: decimal outside;
    margin: 20px;
    line-height: 27px;
    padding-left: 17px; }
  ul {
    list-style: disc outside;
    margin: 20px;
    line-height: 27px;
    padding-left: 17px; }
  figure:not(.highlight) {
    // background: $white
    background: transprent;
    margin: 40px;
    padding: 20px;
    text-align: center;
    border-radius: 3px;
    img {
      display: inline-block;
      vertical-align: top; } }
  figcaption {
    color: $text;
    font-size: $size-small;
    margin-top: 20px; }
  blockquote {
    background: $white;
    border-left: 4px solid #d8e4ec;
    color: #7a7a7a;
    font-style: italic;
    margin: 30px 0;
    padding: 15px 30px;
    font-size: 16px;
    p {
      margin: 0; } }
  table {
    background: rgba(245, 248, 250, 0.41);
    box-shadow: 0 1px 2px rgba(black, 0.05);
    font-size: $size-small;
    margin: 20px 0;
    width: 100%;
    th,
    td {
      border: 1px solid hsl(0, 0%, 90%);
      padding: 5px;
      text-align: left;
      &.empty {
        background: none;
        border: none; }
      code {
        font-size: 14px;
        padding-bottom: 2px; } }
    thead {
      tr {
        background: hsl(0, 0%, 100%); } } }
  code,
  pre {
    //background: $simple-code
    color: $pre-color;
    font-size: $size-small;
    padding: 2px 4px;
 }    //border-radius: 3px
  code {
    position: relative;
    top: -2px; }
  kbd {
    display: inline-block;
    margin: 0 2px;
    padding: 0 6px;
    border: 1px solid #d7e2e8;
    border-radius: 3px;
    background-color: $simple-code;
    text-shadow: 0 1px 0 #ffffff;
    box-shadow: 0 .8px 0 rgba(148, 165, 181, 0.2), 0 0 0 0.8px #fff inset;
    white-space: nowrap;
    font-size: $size-small;
    font-weight: 600; }
  pre {
    padding: 10px; }
  .highlight {
    code,
    pre {
      background: $white; } }
  @include on($desktop) {
    font-size: $size-content-desktop;
    table {
      margin-left: -80px;
      margin-right: -80px;
      width: 940px; } } }

#non-visited a {
  color: $link; }

.cover {
  @include on($desktop) {
    display: block;
    position: relative;
    img {
      display: block !important; } } }

a.cover {
  @include on($desktop) {
    &:after {
      @include overlay;
      background: black;
      content: "";
      display: block;
      opacity: 0;
      transition: opacity $speed $easing; }
    &:hover {
      &:after {
        opacity: 0.29; } } } }

.device {
  background: 0 0 no-repeat;
  position: relative;
  img {
    position: absolute; }
  &.imac {
    background-image: url("/images/devices/imac.png");
    height: 1685px;
    width: 2100px;
    img {
      left: 90px;
      top: 90px; } }
  &.ipad-h {
    background-image: url("/images/devices/ipad-h.png");
    height: 891px;
    width: 1260px;
    img {
      left: 118px;
      top: 61px; } }
  &.ipad-v {
    background-image: url("/images/devices/ipad-v.png");
    height: 1260px;
    width: 891px;
    img {
      left: 62px;
      top: 118px; } }
  &.macbook-air {
    background-image: url("/images/devices/macbook-air.png");
    height: 1132px;
    width: 1970px;
    img {
      left: 266px;
      top: 83px; } } }

.demo {
  text-align: center;
  a {
    &,
    &:visited {
      @include button;
      background: $link;
      color: white;
      display: inline-block;
      padding: 20px 32px;
      vertical-align: top; } } }

.heading {
  margin: $offset;
  text-align: center;
  h1,
  h2 {
    line-height: 1; }
  h1 {
    color: $title;
    font-size: $size-h1; }
  h2 {
    color: $subtitle;
    font-size: $size-h2;
    &.sub-heading {
      font-weight: 300; } }
  @include on($desktop) {
    h1 {
      font-size: $size-h1-desktop; }
    h2 {
      font-size: $size-h2-desktop; } } }

.follow {
  text-align: center;
  a {
    border-radius: 100%;
    color: $border;
    display: inline-block;
    height: 80px;
    margin-top: 20px;
    vertical-align: top;
    width: 80px;
    .fa {
      font-size: 42px;
      line-height: 80px; }
    &.twitter {
      @include pop($twitter);
      color: $twitter;
      &:hover {
        color: white; } }
    &.github {
      @include pop($github);
      color: $github;
      &:hover {
        color: white; } }
    &.lastfm {
      @include pop($lastfm);
      color: $lastfm;
      &:hover {
        color: white; } } } }

.icons {
  li {
    display: inline-block;
    font-size: 28px;
    margin-right: 10px;
    vertical-align: top; } }

.introduction {
  @extend .subtitle; }

.line-drawing {
  display: none;
  transition: opacity 0.5s;
  path {
    fill: none;
    stroke: $black;
    stroke-width: 1;
    &.line-round {
      stroke-linecap: round; } }
  @include on($desktop) {
    background: white;
    display: block;
    margin-top: -300px;
    mix-blend-mode: screen;
    position: absolute;
    right: -25%;
    top: 50%; } }

.link {
  a:hover {
    text-decoration: underline; } }

.pagination {
  margin-top: 20px;
  a {
    @include pagi;
    padding-top: 15px;
    & + a {
      margin-top: 20px; }
    &[rel="next"] {
      display: none; }
    strong {
      transition: all $speed $easing; } }
  @include on($desktop) {
    display: flex;
    justify-content: space-between;
    a {
      & + a {
        margin-top: 0; }
      &[rel="next"] {
        display: block;
        margin-right: 20px; }
      &[rel="previous"] {
        flex: 1;
        margin-right: 20px; } } } }

.period {
  background: white;
  border-top: 1px solid $link;
  color: $link;
  display: block;
  letter-spacing: 2px;
  padding: 10px;
  right: 0;
  text-transform: uppercase;
  &.year {
    padding-top: 20px; }
  &.season-wet {
    border-top-color: #a9caf1;
    color: #a9caf1; }
  // &.season-spr
  //   border-top-color: #c1ea95
  //   color: #c1ea95
  &.season-dry {
    border-top-color: #fbd596;
    color: #fbd596; }
  // &.season-aut
  //   border-top-color: #c8af99
  //   color: #c8af99
  @include on($desktop) {
    border-top: 1px solid $link;
    color: $link;
    left: 80px;
    position: absolute;
    width: 80px;
    &.year {
      left: 0;
      padding-top: 10px;
      z-index: 1; }
    &.affix {
      left: 50%;
      margin-left: -390px;
      position: fixed;
      top: 0;
      &.year {
        margin-left: -470px; } } } }

.permalink {
  @include overlay; }

.rank {
  //background: $link
  border: 1px solid $grey;
  background: $white;
  border-radius: 100%;
  color: $black;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: top;
  width: 40px;
  @include on($desktop) {
    font-size: $size-h2;
    height: 50px;
    line-height: 46px;
    width: 50px; } }

.subtitle {
  color: $subtitle;
  line-height: 1.425;
  a {
    color: $subtitle; } }

.tabs {
  padding-top: 20px;
  a {
    @include butanime;
    display: inline-block;
    margin: 10px 5px 0;
    padding: 10px 15px;
    text-align: center;
    vertical-align: top;
    &:hover {
      background: $hover;
      // border-color: $black
      color: $link; }
    &.active {
      background: $link;
      border-color: $link;
      color: white; } } }

.title {
  color: $title;
  font-size: $size-h2;
  line-height: 1.09;
  transition: font-size $speed $easing;
  strong {
    color: $title; }
  a {
    transition-duration: $speed / 2; } }

.topics {
  @extend .clients; }

.tools {
  @extend .clients; }

.post-share {
  background: $background;
  border-radius: 3px;
  margin-top: $offset;
  padding: 20px;
  span {
 }    // font-style: oblique
  .fa {
    color: $twitter;
    font-size: 28px;
    float: right;
    line-height: 32px;
    margin-left: 20px; }
  a {
    border: 1px solid $twitter;
    border-radius: 3px;
    font-size: 14px;
    color: $twitter;
    display: inline-block;
    line-height: 20px;
    margin-top: 10px;
    padding: 3px 10px;
    vertical-align: middle;
    &:hover {
      background: $twitter;
      color: white; } }
  @include on($desktop) {
    padding-left: 30px;
    br {
      display: none; }
    span {
      display: inline-block;
      padding: 6px 0;
      vertical-align: top; }
    a {
      margin: 2px 0 0 10px; } } }
