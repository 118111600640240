.c-fill {
  fill: none; }

.c-stroke,
.c-logo {
  fill: $logo; }

#svg-logo {
  transition: all 86ms ease;
  border-radius: 100%;
  &:hover {
    .c-fill {
      fill: $logo; }
    .c-stroke {
      fill: none; }
    .c-logo {
      fill: white; } } }
