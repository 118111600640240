// base color
// black: #282c37
// grey: #9baec8
// greywhite: #d9e1e8
// blue: #2b90d9


$white: #f0f9ff;
$ghostwhite: #f5f5f5;
$lighter: #d6dae0;
$light: #abb3bf;
$medium: #69768c;
$dark: #404854;
$black: #282c37;
$simple-code: rgba(0,0,0,.05);
$hover: #ececec;
$pre-color: #e0033f;

$ri: #0077c7;
$logo: #333;

$blue: #42afe3;
$green: #54c294;
$orange: #f68b39;
$purple: #847bb9;
$red: #db574f;
$turquoise: #42e3d0;
$yellow: #fce473;
$grey: #e6e6e6;
$cyan: #24ffde;
$black-soft: #656565;

// link category color
$is-design: #914fa6;
$is-gaming: #f2a129;
$is-life: #337875;
$is-programming: #d13026;
$is-science: #476cbb;

$web: #6ecd56;
$html5: #f16529;
$css3: #33a9dc;

$brique: #de7f2b;
$beige: #b8ac8d;

$improbable: #ff2e01;

$marksheet: #d16098;
$marksheet-dark: #f4a35b;

$marksheet-1: #f4a35b;
$marksheet-2: #f4655b;
$marksheet-3: #d16098;
$marksheet-4: #ca3469;

$ivan-background: #eae8e3;
$ivan-border: #b8ac8d;
$ivan-link: #de7f2b;
$ivan-text: #4d4d4d;
$ivan-title: #1b1e21;

$twitter: #1da1f2;
$facebook: #6d84b4;
$instagram: #3f729b;
$github: #333333;
$linkedin: #0077b5;
$lastfm: #d00a01;
$soundcloud: #ff8800;
$rss: #f26522;

$text: $medium;
$title: #1d1d1d;
$subtitle: $light;
$introduction: $light;
$link: $ri;
$visited: $light;
$background: $white;
$border: $lighter;
$border-pagi: $grey;

$speed: 86ms;
$easing: ease-out;

// $font-family-primary: "Roboto", "Source Sans Pro", Arial, sans-serif
$font-family-primary: -apple-system, BlinkMacSystemFont, "Proxima Nova", Ubuntu, Roboto, "Helvetica Neue", Helvetica, Noto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$offset: 60px;
$offset-mobile: 80px;
$offset-desktop: 120px;

$size-default: 16px;
$size-content-desktop: 18px;
$size-h1: 34px;
$size-h1-widescreen: 48px;
$size-h1-desktop: 41px;
$size-h2: 26px;
$size-h2-desktop: $size-h1;
$size-h2-link: 25px;
$size-h3: 21px;
$size-small: 14px;
$size-title: 30px;
