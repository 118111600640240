.articles {
  padding-top: $offset-mobile;
  position: relative;
  hr {
    border-top: 1px solid $border;
    display: block;
    margin: 0; }
  .article {
    padding: 20px;
    padding-left: 40px;
    position: relative;
    .fa-star {
      color: $yellow;
      font-size: 21px;
      left: 12px;
      position: absolute;
      top: 23px; }
    .info {
      float: right;
      padding-top: 4px;
      text-align: right; }
    .title {
      a {
        color: $title;
        &:hover {
          //background: $link
          color: $link; }
        &:visited {
          color: $visited;
          &:hover {
            color: $link; } } } }
    .introduction {
      margin-top: 7px; }
    &.recommended {
      .title {
        font-weight: 600; } }
    & + .article {
      border-top: 1px solid $border;
      // &:last-child
 } }      //   padding-bottom: 60px
  @include on($desktop) {
    padding-top: $offset-desktop;
    hr {
      margin: 0 0 0 160px; }
    .article {
      margin-left: 160px;
      padding: 20px 0;
      .fa-star {
        left: -28px; } } }
  &.only-recommended {
    .article:not(.recommended) {
      background: $background;
      padding-top: 5px;
      padding-bottom: 5px;
      .info,
      .title,
      .introduction {
        display: none; } } } }

.console {
  .item {
    border-top: 1px solid $border;
    padding: 20px;
    position: relative;
    &:last-child {
      margin-bottom: 0; }
    .rank {
      float: right; }
    .name {
      @extend .title;
      font-weight: 400; }
    .developer {
      @extend .subtitle;
      margin: 5px 0 20px; }
    .link {
      margin-bottom: 20px; } }
  @include on($desktop) {
    .item {
      min-height: 421px;
      padding: $offset $offset $offset 400px;
      .rank {
        left: 0;
        position: absolute;
        top: $offset; }
      .cover {
        left: 80px;
        max-width: 300px;
        position: absolute;
        top: $offset; }
      .name {
        font-size: $size-title !important; } // @extend priority BS
      .developer {
        font-size: $size-h3; } } } }

.dots {
  display: none;
  margin-top: -64px;
  position: fixed;
  top: 50%;
  z-index: 1;
  a {
    color: $title;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 32px;
    line-height: 16px;
    padding: 8px;
    padding-left: 0;
    position: relative;
    white-space: nowrap;
    &:before {
      background: #abb3bf;
      border-radius: 50%;
      content: "";
      display: block;
      float: left;
      height: 8px;
      margin: 4px 12px;
      width: 8px; } }
  li {
    border-radius: 0 3px 3px 0;
    display: block;
    overflow: hidden;
    transition: none 294ms;
    transition-property: width;
    width: 32px;
    &:hover {
      box-shadow: 0 1px 2px rgba(black, 0.05), inset 0 0px 0 1px rgba(black, 0.1);
      width: 124px; }
    &.active {
      a {
        &:before {
          background: $link;
          height: 12px;
          margin: 4px 10px;
          width: 12px; } } } }
  @include on($tablet) {
    display: block; } }

.favourites {
  padding-top: $offset-mobile;
  .container > .title {
    font-size: $size-h1;
    margin-bottom: 20px; }
  @include on($desktop) {
    padding-top: $offset-desktop; } }

.links {
  margin: 0 20px;
  padding-top: $offset-mobile;
  .container > .title {
    font-size: $size-h1;
    margin-bottom: 20px; }
  &-item {
    position: relative;
    & + & {
      margin-top: 20px;
      margin-bottom: 20px; }
    @include on($desktop) {
 } }      //margin-left: 70px
  &-category {
    margin-bottom: 10px;
    span {
      background: #8e8e93;
      border-radius: 3px;
      color: white;
      display: inline-block;
      font-size: $size-small;
      height: 24px;
      line-height: 24px;
      padding: 0 8px;
      vertical-align: top; }
    &.is-design {
      span {
        background: $is-design; } }
    &.is-gaming {
      span {
        background: $is-gaming; } }
    &.is-life {
      span {
        background: $is-life; } }
    &.is-programming {
      span {
        background: $is-programming; } }
    &.is-science {
      span {
        background: $is-science; } } }
  &-title {
    font-size: $size-h2;
    line-height: 1.09;
    transition: font-size $speed $easing;
    a {
      color: $title;
      transition-duration: $speed / 2;
      &:hover {
        color: $link; } } }
  &-domain {
    color: $light;
    font-size: $size-small;
    margin-top: 4px; }
  &-description {
    margin-top: 7px;
    line-height: 1.425; }
  @include on($tablet) {
    &-item {
      padding-left: 160px; }
    &-category {
      left: 0;
      margin-bottom: 0;
      position: absolute;
      text-align: right;
      top: 5px;
      width: 120px; } }
  @include on($desktop) {
    margin: 0;
    padding-top: $offset-desktop;
    &-item {
      & + & {
        margin-top: 60px; } } } }

.hello {
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  width: 100%;
  .hello-previous,
  .hello-next {
    display: none;
    height: 60px;
    left: 0;
    line-height: 60px;
    position: absolute;
    text-align: center;
    width: 100%;
    a {
      display: block; } }
  .hello-next {
    bottom: 0; }
  .hello-previous {
    top: 0; }
  .hello-background,
  .hello-screenshot {
    @include overlay;
    bottom: auto;
    display: none;
    height: 600px;
    left: auto;
    margin-top: -300px;
    position: absolute;
    right: -25%;
    top: 50%;
    width: 100%; }
  .hello-content {
    display: table;
    height: 100vh;
    position: relative;
    vertical-align: middle;
    width: 100%;
    z-index: 1; }
  .hello-screenshot {
    img {
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%; }
    &.animate img {
      animation: jelly-pop 1000ms 1000ms linear both; } }
  .hello-text {
    display: table-cell;
    padding: 0 40px;
    position: relative;
    vertical-align: middle;
    .title {
      color: $text;
      margin-bottom: $size-h1;
      a {
        @include grow($improbable);
        color: white;
        padding: 0 0.25em 0.05em;
        white-space: nowrap;
        &:before {
          border-radius: 0;
          opacity: 1; }
        span {
          position: relative; }
        &:hover {
          &:before {
            bottom: -10px;
            left: -10px;
            right: -10px;
            top: -10px; } } }
      strong {
        font-weight: bold; }
      @media screen and (min-height: 400px) and (min-width: 600px) {
        font-size: $size-h1; }
      @media screen and (min-height: 600px) and (min-width: 800px) {
        font-size: $size-h1-desktop; }
      @media screen and (min-height: 800px) and (min-width: 1000px) {
        font-size: $size-h1-widescreen; } }
    .subtitle {
      margin-top: 20px; } }
  &:first-child {
    .hello-text {
      padding-top: 60px; } }
  &.hello-improbable {
    .hello-background {
      background-image: radial-gradient(ellipse farthest-side at 29% 29%, saturate($improbable, 100%), saturate(lighten($improbable, 10%), 100%));
      @include hello-gradient($improbable, lighten($improbable, 10%)); }
    .hello-screenshot img {
      height: 275px;
      margin: -153px 0 0 -219px; } }
  &.hello-marksheet {
    .hello-background {
      @include hello-gradient($marksheet, $marksheet-dark); }
    .hello-screenshot img {
      height: 322px;
      margin: -172px 0 0 -334px; }
    .title a {
      @include grow($marksheet);
      &:before {
        border-radius: 0;
        opacity: 1; } } }
  &.hello-ivan-ballini {
    .hello-background {
      @include hello-gradient($brique, $beige); }
    .hello-screenshot img {
      height: 408px;
      margin: -261px 0 0 -270px; }
    .title a {
      @include grow($brique);
      &:before {
        border-radius: 0;
        opacity: 1; } } }
  @include on($desktop) {
    .hello-background,
    .hello-screenshot {
      display: block; }
    .hello-text {
      .title {
        padding-right: 50%; }
      .follow {
        .title {
          padding-right: 0; } } } } }

.post {
  padding: $offset-mobile 20px 20px;
  position: relative;
  .info {
    float: right;
    margin: 0 0 20px 20px;
    text-align: right; }
  .title {
    font-size: $size-h1; }
  .introduction {
    font-size: $size-h3;
    margin: 5px 0 60px; }
  @include on($desktop) {
    padding: $offset-desktop 0;
    .container {
      padding: 0 80px; }
    .info {
      margin-bottom: 0; } } }

.projects {
  padding-top: $offset-mobile;
  .project {
    overflow: hidden;
    padding: 30px;
    position: relative;
    a:hover {
      text-decoration: underline; }
    .device {
      display: none; }
    .date {
      margin-bottom: 20px;
      position: relative;
      &:after {
        background: white;
        bottom: -10px;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 60px; } }
    .logo {
      display: inline-block;
      height: 200px;
      margin-bottom: 20px;
      vertical-align: top;
      img {
        display: block; } }
    .title {
      color: $title;
      font-weight: bold;
      margin-top: 20px;
      a {
        &:hover {
          padding: 0; } } }
    .url {
      margin: 5px 0 20px; }
    .content {
      color: inherit;
      font-size: $size-default;
      p {
        margin: 0;
        a {
          img {
            text-decoration: none; } }
        & + p {
          margin-top: 10px; } } }
    .icons {
      margin-top: 20px;
      opacity: 0.48; }
    &.bulma {
      @include project(#f5f7fa, #69707a, #222324, #222324, #1fc8db); }
    &.marksheet {
      @include project($marksheet, #f4f1ce, white);
      background-color: $marksheet;
      text-shadow: 0 1px 2px rgba(black, .2); }
    &.barn-dor {
      @include project(#121212, #69707a, #ebb550, white); }
    &.ivan-ballini {
      @include project($ivan-background, $ivan-text, $ivan-link, $ivan-title); }
    &.css-off-2013 {
      @include project(#cdc9dd, #6e6c77, white, black); }
    &.juketube {
      @include project(#eee, #888, #df1f1f, #22242a); }
    &.bbxdesign {
      @include project(#f5574c, rgba(white, 0.71), white); }
    &.cambodia-vietnam-2010 {
      @include project(#dacfb1, #6b6450, #914839, #485c39, #3b5757); }
    &.yeek {
      @include project(#eeeff2, #4c566c, #0589f5, #040404); }
    &.editions-magellan {
      @include project(#332003, rgba(white, 0.29), #c78312, white); }
    &.golf-de-margaux {
      @include project(#96c909, rgba(black, 0.48), #fff, #1a397f); }
    &.bbxworld {
      @include project(#362f2d, rgba(white, 0.48), #8dc63f, white); } }
  @include on($desktop) {
    padding-top: $offset-desktop;
    .project {
      padding: 0;
      width: 100%;
      .device {
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: scale(0.5) translate3d(80px, -100%, 0);
        transform-origin: left center;
        z-index: 1;
        &.ipad-h,
        &.ipad-v {
          transform: scale(0.75) translate3d(80px, -62.5%, 0); } }
      .title {
        font-size: $size-h1; }
      .container {
        align-items: center;
        display: flex;
        min-height: 100vh;
        padding: 200px 0;
        width: 100%;
        .project-content {
          width: 50%; } } } } }

@mixin switch {
  display: inline-block;
  text-align: center;
  vertical-align: top; }

.switch {
  @include switch;
  border: 1px solid $border;
  border-radius: 3px;
  color: $text;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
  padding: 10px 95px 10px 15px;
  position: relative;
  width: 270px;
  .switch-label {
    @include switch;
    font-style: normal;
    position: relative;
    &:after {
      color: $title;
      content: "?";
      left: 104%;
      position: absolute; } }
  .switch-gear {
    @include switch;
    background: $background;
    border-radius: 20px;
    box-shadow: inset 0 1px 2px rgba(black, 0.14), inset 0 0 0 1px $border;
    color: $text;
    font-size: $size-small;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 70px;
    .switch-rail {
      @include clearfix;
      display: block;
      height: 30px;
      transform: translate3d(-40px, 0, 0);
      transition: all $speed $easing;
      width: 110px;
      .switch-yes,
      .switch-thumb,
      .switch-no {
        @include switch;
        float: left;
        height: 30px;
        width: 40px; }
      .switch-yes {
        padding-right: 6px;
        text-align: right; }
      .switch-thumb {
        background: white;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px $border;
        width: 30px; }
      .switch-no {
        padding-left: 10px;
        text-align: left; } } }
  &:hover {
    background: $white;
    border-color: $grey;
    color: $title; }
  &.is-active,
  &.is-active:hover {
    border-color: $green;
    color: $title;
    .switch-label {
      &:after {
        animation: jelly-pop 1000ms linear both;
        color: $green;
        content: "!"; } }
    .switch-gear {
      background: $green;
      box-shadow: inset 0 1px 2px rgba(black, 0.29), inset 0 0 0 1px $green;
      color: white;
      .switch-rail {
        transform: translate3d(0, 0, 0); } } } }
