.highlight {
  background-color: $white;
  color: black;
  overflow: auto;
  border-radius: 3px;
  .c {
    color: #999988;
    font-style: italic; }
  .err {
    color: #a61717;
    background-color: $white; }
  .k, .o {
    color: #1e1e1f;
    font-weight: bold; }
  .p {
    color: #1e1e1f; }
  .cm {
    color: #999988;
    font-style: italic; }
  .cp {
    color: #999999;
    font-weight: bold; }
  .c1 {
    color: #999988;
    font-style: italic; }
  .cs {
    color: #999999;
    font-weight: bold;
    font-style: italic; }
  .gd {
    color: #000000;
    background-color: #ffdddd;
    .x {
      color: #000000;
      background-color: #ffaaaa; } }
  .ge {
    font-style: italic; }
  .gr {
    color: #aa0000; }
  .gh {
    color: #999999; }
  .gi {
    color: #000000;
    background-color: #ddffdd;
    .x {
      color: #000000;
      background-color: #aaffaa; } }
  .go {
    color: #888888; }
  .gp {
    color: #b1b1b1; }
  .gs {
    font-weight: bold; }
  .gu {
    color: #aaaaaa; }
  .gt {
    color: #aa0000; }
  .kc {
    font-weight: bold; }
  .kd {
    color: #a71d5d;
    font-weight: bold; }
  .kp, .kr {
    font-weight: bold; }
  .kt {
    color: #445588;
    font-weight: bold; }
  .m {
    color: #009999; }
  .s {
    color: #d14; }
  .n, .nb {
    color: #1e1e1f; }
  .na {
    color: #008080; }
  .nb {
    color: #0086B3; }
  .nc {
    color: #445588;
    font-weight: bold; }
  .no {
    color: #008080; }
  .ni {
    color: #800080; }
  .nl {
    color: #888888; }
  .ne, .nf {
    color: #990000;
    font-weight: bold; }
  .nn {
    color: #555555; }
  .nt {
    color: #000080; }
  .nv {
    color: #008080; }
  .nx {
    color: #1e1e1f; }
  .ow {
    font-weight: bold; }
  .w {
    color: #bbbbbb; }
  .mf, .mh, .mi, .mo {
    color: #009999; }
  .sb, .sc, .sd, .s2, .se, .sh, .si, .sx {
    color: #d14; }
  .sr {
    color: #009926; }
  .s1 {
    color: #d14; }
  .ss {
    color: #990073; }
  .bp {
    color: #999999; }
  .vc, .vg, .vi {
    color: #008080; }
  .il {
    color: #009999; }
  @include on($desktop) {
    margin: 0 -80px; } }
